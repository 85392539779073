import { formatPhoneNumberIntl } from "react-phone-number-input"
import styled from "styled-components"

import IconButton from "@material-ui/core/IconButton"
import { E164Number } from "libphonenumber-js/core"

import { breakpoint } from "src/constants/breakpoints"
import { IGuestExtended } from "src/data/guests/types/guestTypes"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import { GridTable } from "src/ui/GridTable/GridTable"
import EditIcon from "src/ui/icons/edit.svg"
import TrashIcon from "src/ui/icons/trash.svg"
import { MInfo } from "src/ui/Info/MInfo"
import { LocalizedDate } from "src/ui/LocalizedDate/LocalizedDate"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function GuestTable({
  guests,
  onEdit,
  onRemove,
  loading,
  canEdit,
  timezone,
}: {
  guests: IGuestExtended[]
  onEdit: (guest: IGuestExtended) => void
  onRemove: (guest: IGuestExtended) => void
  canEdit: boolean
  loading?: boolean
  timezone?: string
}) {
  const { org } = useOrganization()

  const { t, langKeys } = useTranslate()

  const fetchIntegrations = useFetchIntegrations(
    {
      orgId: org.id,
      filters: { includeExternalIntegrations: true },
    },
    { enabled: !!guests.length }
  )

  if (!guests.length) return null

  const header = [
    <TableCell key={"guest"}>{t(langKeys.guest, { count: 2 })}</TableCell>,
    <TableCell key={"guest-check-in"}>{t(langKeys.guest_check_in)}</TableCell>,
    <TableCell key={"guest-check-out"}>
      {t(langKeys.guest_check_out)}
    </TableCell>,
    <TableCell key={"phone"}>{t(langKeys.phone)}</TableCell>,
    <TooltipWrapper key="access-code">
      <span>{t(langKeys.lock_access_code)}</span>{" "}
      <MInfo content={<div>{t(langKeys.lock_access_code_description)}</div>} />
    </TooltipWrapper>,
    <TableCell key={"guest-source"}>{t(langKeys.guest_source)}</TableCell>,
    <TableCell key={"misc"}></TableCell>,
  ]

  return (
    <GridTable
      header={header}
      rows={guests.map((guest) => {
        const integrationName = fetchIntegrations.data?.integrations.find(
          (i) => i.integration_identifier === guest.source
        )?.name

        return (
          <GuestRow
            guest={guest}
            key={guest.guest_id}
            onEdit={() => onEdit(guest)}
            onRemove={() => onRemove(guest)}
            loading={loading}
            canEdit={canEdit}
            integrationName={integrationName}
            timezone={timezone}
          />
        )
      })}
      templateColumns={"1fr 1fr 1fr 1fr 1fr auto auto"}
      useFallbackResponsiveMode
    />
  )
}

interface IGuestRow {
  guest: IGuestExtended
  onEdit: () => void
  onRemove: () => void
  canEdit: boolean
  loading?: boolean
  integrationName?: string
  timezone?: string
}

function GuestRow({
  guest,
  onEdit,
  onRemove,
  canEdit,
  loading,
  integrationName,
  timezone,
}: IGuestRow) {
  const removeDisabled = loading || guest.readonly
  const deleteTooltip = integrationName
    ? `Guest can only be deleted from the main source: ${integrationName}`
    : ""

  const validationErrors = guest.validation_errors || []

  const errorMsg =
    validationErrors.length > 0
      ? validationErrors
          .filter((err) => err.error_message && err.error_message.length > 0)
          .map((err) => err.error_message)
          .join(". ") + "."
      : ""

  return (
    <>
      <div>
        <div>
          <TextButton
            onClick={onEdit}
            disabled={loading || !canEdit}
            style={{ textAlign: "left" }}
          >
            {guest.name}
          </TextButton>
        </div>
      </div>

      <div>
        {guest.checkin_time && (
          <LocalizedDate date={guest.checkin_time} timezone={timezone} />
        )}
      </div>

      <div>
        {guest.checkout_time && (
          <LocalizedDate date={guest.checkout_time} timezone={timezone} />
        )}
      </div>

      <div>
        <PhoneCell>
          {formatPhoneNumberIntl(guest.phone_number as E164Number)}

          {!!errorMsg && (
            <MTooltip title={errorMsg}>
              <span>
                <StyledErrorIcon state="warning" />
              </span>
            </MTooltip>
          )}
        </PhoneCell>
      </div>

      <div>{guest.access_code}</div>

      <div>
        <SourceIcon src={guest.icon} alt={`${guest.sourceName} icon`} />
      </div>

      <div>
        <ActionButtons>
          <StyledIconButton
            aria-label="edit"
            onClick={onEdit}
            disabled={loading || !canEdit}
          >
            <EditIcon width={24} />
          </StyledIconButton>

          {deleteTooltip ? (
            <MTooltip title={deleteTooltip}>
              <span>
                <StyledIconButton
                  aria-label="delete"
                  onClick={onRemove}
                  disabled={removeDisabled || !canEdit}
                >
                  <TrashIcon width={24} />
                </StyledIconButton>
              </span>
            </MTooltip>
          ) : (
            <StyledIconButton
              aria-label="delete"
              onClick={onRemove}
              disabled={removeDisabled || !canEdit}
            >
              <TrashIcon width={24} />
            </StyledIconButton>
          )}
        </ActionButtons>
      </div>
    </>
  )
}

const PhoneCell = styled.div`
  display: flex;
  align-items: center;
`

const StyledErrorIcon = styled(ErrorIcon)`
  margin-left: ${spacing.XS};
  display: inline-block;
`

const TableCell = styled.div`
  white-space: nowrap;
`

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  white-space: nowrap;
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (${breakpoint.mediumUp}) {
    justify-content: flex-end;
  }
`

const StyledIconButton = styled(IconButton)`
  opacity: 50%;

  svg.minut-svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 100%;
  }
  &:disabled {
    opacity: 20%;
  }
`

const SourceIcon = styled.img`
  width: 18px;
`
